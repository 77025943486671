import { defaultPageWidthClass } from "@/components/layout/PageLayout";
import SearchBox from "@/components/search/SearchBox";
import SettingsButton from "@/components/settings/SettingsButton";
import SupportButton from "@/components/support/SupportButton";
import { LoggedOutButtons } from "@/components/ui/LoggedOutButtons";
import Logo from "@/components/ui/Logo";
import { useDevTools } from "@/hooks/useUIStore";
import { pluralize } from "@/lib/stringUtils";
import { uiStore } from "@/stores/uiStore";
import { FeatureFlag } from "@/types";
import { ClockIcon, FlagIcon } from "@heroicons/react/20/solid";
import { QueueListIcon, Squares2X2Icon } from "@heroicons/react/24/solid";
import { useStore } from "@nanostores/react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useFeatureFlagEnabled } from "posthog-js/react";
import React, { useState } from "react";
import { twJoin } from "tailwind-merge";
import { COLOR_ACTIVABLE, COLOR_ACTIVE, COLOR_INACTIVE } from "@/styles/colorConstants";

export default function Navbar({
  initialQuery,
  noSearch,
  titleButtons,
}: {
  initialQuery?: string;
  noSearch?: boolean;
  titleButtons?: React.ReactNode;
}) {
  const user = useStore(uiStore.user);
  const isDev = useDevTools();
  const betaMode = useFeatureFlagEnabled(FeatureFlag.BetaUI);

  const entityVisitCount = useStore(uiStore.entityVisitCount);
  const profileVisits = (entityVisitCount?.visitedIds as string[])?.length;

  return (
    <nav className="py-2 px-2 sm:px-6 h-[56px] flex gap-4 justify-between border-b">
      <div
        className={twJoin(
          defaultPageWidthClass,
          "flex gap-x-2 sm:gap-x-6 items-center justify-between",
        )}
      >
        <Link href={user ? "/dashboard" : "/"}>
          <Logo className="max-w-8" />
        </Link>
        {user && !noSearch && (
          <div className="flex-1 mx-4">
            <SearchBox
              initialQuery={initialQuery}
              submitButton
              className="max-w-72 lg:max-w-96"
              magnifyClassName="left-4"
              placeholder="Search for a person or company"
            />
          </div>
        )}
        {user && (
          <div className="hidden sm:flex space-x-1 text-gray-500">
            <NavLink href="/dashboard">
              <Squares2X2Icon className="w-4 h-4 mr-2 hidden md:inline" /> Dashboard
            </NavLink>
            {/* <NavLink href="/meetings">Meetings</NavLink> */}
            <NavLink href="/recents">
              <ClockIcon className="w-4 h-4 mr-2 hidden md:inline" /> Recents
            </NavLink>
            <NavLink href="/lists">
              <QueueListIcon className="w-4 h-4 mr-2 hidden md:inline" /> Lists
            </NavLink>
          </div>
        )}
        <div className="flex gap-x-2 items-center">
          {isDev && (
            <div
              data-attr="posthog-early-access-features-button"
              className="cursor-pointer rounded-md hover:bg-gray-200/40 p-1 mr-3 hidden md:block"
              data-tooltip-content={"Feature Flags"}
              data-tooltip-id="tooltip"
            >
              <FlagIcon
                className={twJoin("h-4 w-4", betaMode ? "text-orange-400" : "text-gray-300")}
              />
            </div>
          )}
          {titleButtons}
          <SupportButton />
          {entityVisitCount && (
            <div
              data-tooltip-id="tooltip"
              data-tooltip-content={`${pluralize(profileVisits, "profile")} viewed today`}
              className="rounded-full bg-brand-200 font-bold font-mono text-brand-800 px-2 py-1"
            >
              {profileVisits}
            </div>
          )}
          {user ?
            <SettingsButton />
          : <LoggedOutButtons />}
        </div>
      </div>
    </nav>
  );
}

export const NavLink = ({
  href,
  onClick,
  children,
}: {
  href: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  children: React.ReactNode;
}) => {
  const [effect, setEffect] = useState(false);
  const pathname = usePathname();
  const baseNavClass = "p-2 rounded-md";

  return (
    <Link
      className={twJoin(
        pathname === href ? COLOR_ACTIVE : COLOR_INACTIVE,
        COLOR_ACTIVABLE,
        "flex items-center font-medium",
        baseNavClass,
        effect && "animate-singlePulse",
      )}
      onClick={(e) => {
        setEffect(true);
        onClick?.(e);
      }}
      onAnimationEnd={() => setEffect(false)}
      href={href}
    >
      {children}
    </Link>
  );
};
