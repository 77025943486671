import Button, { ButtonLink } from "@/components/ui/Button";
import { logger } from "@/lib/logger";
import { PROD_CHROME_EXTENSION, uiStore } from "@/stores/uiStore";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { useStore } from "@nanostores/react";
import Link from "next/link";
import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";

function checkForCookie() {
  if (document.visibilityState === "visible") {
    uiStore.checkCookieFragments({}, true);
  }
}

export default function CookieBanner() {
  const status = useStore(uiStore.liCookieStatus);
  const shouldShow = status != "unknown" && status != "valid";
  const posthog = usePostHog();

  useEffect(() => {
    if (shouldShow) {
      posthog?.capture("cookie-banner-shown", { status });
    }
  }, [shouldShow]);

  if (!shouldShow) return null;

  const listenForCookie = () => {
    posthog?.capture("cookie-banner-clicked", { status });
    document.removeEventListener("visibilitychange", checkForCookie);
    document.addEventListener("visibilitychange", checkForCookie);
  };

  return (
    <div className="rounded-md border border-yellow-400 bg-yellow-100 p-4 mb-4 text-sm flex items-center">
      Hey you!{" "}
      {status === "logged-out" ?
        <>
          You&apos;re currently logged out of LinkedIn - certain features won&apos;t work.
          <div className="flex-1" />
          <ButtonLink
            href="https://www.linkedin.com/login"
            target="_blank"
            onClick={listenForCookie}
          >
            Log in to LinkedIn
            <ArrowTopRightOnSquareIcon className="h-4 w-4 ml-2 text-white" />
          </ButtonLink>
        </>
      : status == "no-extension" ?
        <>
          Please install the Distill Chrome extension, or
          <Link href="/settings" className="ml-1 text-brand-500 hover:underline">
            update your cookie manually
          </Link>
          .<div className="flex-1" />
          <ButtonLink
            target="_blank"
            href={`https://chromewebstore.google.com/detail/distill/${PROD_CHROME_EXTENSION}`}
            onClick={listenForCookie}
          >
            Install Extension
            <ArrowTopRightOnSquareIcon className="h-4 w-4 ml-2 text-white" />
          </ButtonLink>
        </>
      : <>
          You don&apos;t have any valid cookies.
          <div className="flex-1" />
          <ButtonLink href="/setup">Reconnect</ButtonLink>
        </>
      }
    </div>
  );
}
