import useAutosizeTextArea from "@/components/hooks/useAutosizeTextArea";
import { forwardRef, TextareaHTMLAttributes, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";

type Props = {} & TextareaHTMLAttributes<HTMLTextAreaElement>;

const TextArea = forwardRef<HTMLTextAreaElement, Props>(function TextArea(props, ref) {
  const { children, className, ...rest } = props;

  return (
    <textarea
      ref={ref}
      className={twMerge(
        "border border-gray-300 rounded-md p-2 text-black text-sm leading-6 min-h-[4rem]",
        className,
      )}
      {...rest}
    />
  );
});

export default TextArea;

export function AutoResizeTextArea(props: Props) {
  const [value, setValue] = useState((props.defaultValue as string) || "");
  const ref = useRef<HTMLTextAreaElement>(null);
  useAutosizeTextArea(ref.current, value);

  return (
    <TextArea
      {...props}
      ref={ref}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
        props.onChange?.(e);
      }}
      defaultValue={undefined}
    />
  );
}
